import { Box, Button, Carousel, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Divider } from '@mui/material';
import { Money } from '~/components/Money/money';
import { EmphasisText } from '~/pages/OrderHistory/components/EmphasizeText';
import { InfoText } from '~/pages/OrderHistory/components/InfoText';
import {
    HeaderRow,
    HeaderRowBox,
    HeaderRowBoxHiddenWhenSmall,
    OrderHeader,
} from '~/pages/OrderHistory/OrdersList/OrderCard/OrderHeaderRow/OrderHeaderRow';
import { BoxVisibleAboveMd, BoxVisibleBelowMd } from '~/components/Visibility/VisibleBox';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';
import { StoreOrderSummary } from '~/adapters/services/accounts/stores/types';
import { formatDate } from '~/adapters/date/date';

interface Props {
    card: StoreOrderSummary;
    purchaseLink: string;
}

const OrderContent = styled(Stack)(({ theme }) => ({
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: theme.spacing(2),
}));

export function StorePurchaseCard({ card, purchaseLink }: Props) {
    const slides = card.carouselImages.map((uri) => <CarouselProductImage alt="Product preview" src={uri} key={uri} />);

    return (
        <Box
            sx={(theme) => ({
                mb: 1,
                width: '100%',
                position: 'relative',
                border: `1px solid ${theme.palette.divider}`,
            })}
            data-testid="store purchase card"
        >
            <OrderHeader>
                <HeaderRow>
                    <HeaderRowBox>
                        <InfoText>Order </InfoText>
                        <EmphasisText>#{card.referenceId}</EmphasisText>
                    </HeaderRowBox>
                    <HeaderRowBoxHiddenWhenSmall>
                        <InfoText>Date placed </InfoText>
                        <EmphasisText>{formatDate(card.createdAt)}</EmphasisText>
                    </HeaderRowBoxHiddenWhenSmall>
                    <HeaderRowBox>
                        <InfoText>Total </InfoText>
                        <EmphasisText>
                            <Money value={Number(card.totalPrice)} />
                        </EmphasisText>
                    </HeaderRowBox>
                </HeaderRow>
            </OrderHeader>
            <Divider />
            <OrderContent>
                <Box sx={{ width: '200px' }}>
                    <Carousel slides={slides} sliderHeight="auto" loop={false} />
                </Box>
                <Box flex={1} sx={{ px: 2 }}>
                    <div>Qty {card.quantity > 0 ? card.quantity : 'Canceled'}</div>
                </Box>
                <BoxVisibleAboveMd sx={{ width: 'auto' }}>
                    <Button variant="link" href={purchaseLink}>
                        Purchase detail
                    </Button>
                </BoxVisibleAboveMd>
                <BoxVisibleBelowMd sx={{ pt: 2 }}>
                    <Divider />
                    <Button variant="link" href={purchaseLink}>
                        Purchase detail
                    </Button>
                </BoxVisibleBelowMd>
            </OrderContent>
        </Box>
    );
}
