import React, { ReactNode } from 'react';
import { usePage } from '~/adapters/browser/usePage';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { useInfiniteHealthAndWellnessOrders } from '~/adapters/services/accounts/stores/hooks';
import { Loader } from '~/components/Loader/loader';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { SectionContent } from '../../components/Layout/SectionContent';
import { StorePurchasesList } from './OrdersList/StorePurchasesList';

export interface Props {
    children?: ReactNode;
}

const HealthAndWellness: React.FC<Props> = () => {
    const { id: accountId } = useAccount();

    usePage({ title: 'Order History', reportPath: '/accounts/orders' });

    const { data: storePurchaseData, isLoading: isStorePurchaseLoading } = useInfiniteHealthAndWellnessOrders({
        accountId,
    });
    const storePurchaseItems = useFlattenPaginatedData(storePurchaseData, 'items');

    if (isStorePurchaseLoading) {
        return <Loader title="Loading..." devLabel="store purchase API" />;
    }

    return (
        <ErrorBoundary errorMessage="An error occurred while retrieving your order history">
            <SectionContent>
                <StorePurchasesList storePurchasesItems={storePurchaseItems} />
            </SectionContent>
        </ErrorBoundary>
    );
};

export default HealthAndWellness;
