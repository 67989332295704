import { Button } from '@customink/pigment-react';
import { CushionedBox } from '~/components/Layout/CushionedBox';

interface Props {
    designLabLink: string;
}

export function EmptyOrdersList({ designLabLink }: Props) {
    return (
        <CushionedBox data-testid="empty order history list">
            <p>
                You have no orders placed yet.
                <br />
                Create a design to get started.
            </p>
            <Button href={designLabLink}>Get Started</Button>
        </CushionedBox>
    );
}
