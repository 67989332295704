import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '~/components/Loader/loader';
import { usePage } from '~/adapters/browser/usePage';
import { useOrderDetails } from '~/adapters/services/accounts/orders/hooks';
import { transformOrderDetailsToOrderSummary } from '~/adapters/services/accounts/orders/transformers';
import { useMemo } from 'react';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { OrderCard } from './OrdersList/OrderCard/OrderCard';

const OrderHistory: FC = () => {
    // orderId is guaranteed, because the route doesn't match without a truthy id provided
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const orderId = useParams<{ orderId: string }>().orderId!;

    const { data: orderDetails, isFetching } = useOrderDetails({ orderId: +orderId });
    const orderSummary = useMemo(
        () => (orderDetails ? transformOrderDetailsToOrderSummary(orderDetails) : null),
        [orderDetails],
    );

    usePage({ title: 'Order History', reportPath: '/accounts/orders/details' });

    if (isFetching || !orderSummary) {
        return <Loader title="Loading..." devLabel="order API" />;
    }

    return (
        <ErrorBoundary errorMessage="An error occurred while retrieving this order history">
            <OrderCard order={orderSummary} singleDetail />
        </ErrorBoundary>
    );
};

export default OrderHistory;
