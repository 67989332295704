import { Tab, Tabs } from '@customink/pigment-react';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { OrdersPath } from '~/config';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { useState } from 'react';
import Orders from './Orders';
import HealthAndWellness from './HealthAndWellness';
import { useAccount } from '../../contexts/UserAccount/UserAccountContext';
import { useInfiniteHealthAndWellnessOrders } from '../../adapters/services/accounts/stores/hooks';
import { useFlattenPaginatedData } from '../../adapters/services/shared/useFlattenPaginatedData';

const OrdersTab = OrdersPath;
const HealthAndWellnessTab = '/health_and_wellness' as string;

export default function OrderHistory() {
    const [selectedTab, setSelectedTab] = useState(OrdersPath);

    const { id: accountId } = useAccount();
    const { data: healthAndWellnessData } = useInfiniteHealthAndWellnessOrders({
        accountId,
    });
    const healthAndWellnessOrders = useFlattenPaginatedData(healthAndWellnessData, 'items');

    return (
        <>
            <PageHeader>Order History</PageHeader>
            <ErrorBoundary errorMessage="An error occurred while retrieving the designs">
                {healthAndWellnessOrders.length > 0 && (
                    <Tabs
                        value={selectedTab}
                        onChange={(_, newVal) => setSelectedTab(newVal)}
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Orders" value={OrdersPath} />

                        <Tab label="Health & Wellness" value={HealthAndWellnessTab} />
                    </Tabs>
                )}
                {selectedTab === OrdersTab && <Orders />}
                {selectedTab === HealthAndWellnessTab && <HealthAndWellness />}
            </ErrorBoundary>
        </>
    );
}
