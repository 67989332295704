import { useCallback, useState } from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Tooltip,
    TrashIcon,
    Typography,
} from '@customink/pigment-react';
import { useArtDelete } from '~/adapters/services/accounts/arts/hooks';
import Spinner from '~/components/Spinner';
import './DeleteButton.css';

interface DeleteButtonProps {
    id: number;
    name: string | null;
}

export function DeleteButton({ name, id }: DeleteButtonProps) {
    const [dialogVisible, setDialogVisible] = useState(false);
    const openDialog = useCallback(() => setDialogVisible(true), []);
    const closeDialog = useCallback(() => setDialogVisible(false), []);

    const { mutate, isPending } = useArtDelete();

    const deleteDesign = useCallback(() => mutate(id, { onSettled: closeDialog }), [id, mutate, closeDialog]);

    return (
        <div className="delete-button">
            <Tooltip title="Delete upload" placement="top">
                <IconButton className="delete-button-action" onClick={openDialog} aria-label="Delete the upload">
                    <TrashIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Dialog title="Delete This Upload" open={dialogVisible} onClose={closeDialog}>
                <DialogContent pt={2}>
                    <Alert severity="error" sx={{ mb: 3 }}>
                        <Typography variant="paragraphSmall">
                            This will permanently remove “{name}” from your account.
                        </Typography>
                    </Alert>
                    <Typography paragraph variant="paragraphSmall">
                        You will no longer be able to edit or view this upload. This action is permanent and cannot be
                        undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="primary" onClick={deleteDesign}>
                        {isPending ? <Spinner /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
