import { useCallback, useEffect, useMemo, useState } from 'react';
import { getEnv } from '~/adapters/config/env';
import { Loader } from '~/components/Loader/loader';
import { ErrorLoadingAvalaraAlert } from '~/pages/Settings/tabs/TaxExemptionECM/EmbeddedPortal/ErrorLoadingAvalaraAlert';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { log } from '~/adapters/browser/localLogger';
import { pushErrorNotification, pushNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { useInvalidateCertificatesQuery } from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';

type AvalaraEcmPluginProps = {
    token: string;
    /** US state or territory to get the cert for, full name */
    state: string;
    onClose: () => void;
};

const avalaraApiUrl = getEnv('ACCOUNTS_AVALARA_EXEMPTIONS_API_URL');

const avalaraIframeStyles = `
            button {
                border-radius: 6px !important;
            }
            button:focus {
                box-shadow: none !important;
            }
            
            button.primary {
                color: white !important;
                background-color: ${designTokens.color.secondary.default.value} !important;
            }
            button.primary:hover {
                background-color: ${designTokens.color.secondary.hover.value} !important;
            }
            button.primary:active {
                background-color: ${designTokens.color.secondary.active.value} !important;
            }
            
            button.tertiary, button.secondary {
                color: ${designTokens.color.secondary.default.value} !important;
                background-color: white !important;
                border: 1px solid ${designTokens.color.secondary.default.value} !important;
            }
            button.tertiary:hover, button.secondary:hover {
                color: white !important;
                background-color: ${designTokens.color.secondary.hover.value} !important;
            }
            button.tertiary:active, button.secondary:active {
                color: white !important;
                background-color: ${designTokens.color.secondary.active.value} !important;
            }
            
            button.link {
                color: ${designTokens.color.secondary.default.value} !important;
            }
            
            button.loading {
                background-color: rgba(0, 0, 0, 0.12) !important;
                color: rgba(0, 0, 0, 0.26) !important;
            }

            input:focus {
                box-shadow: none !important;
                border: 1px solid ${designTokens.color.secondary.default.value} !important;
            }

            button s-icon {
                vertical-align: text-top;
            }
        `;

const applyCustomStyles = (formContainer: HTMLDivElement) => {
    const iframe = formContainer?.firstElementChild;
    if (!iframe) {
        log.error('handleAccessIframe iframe is null');
        return false;
    }
    const window = (iframe as HTMLIFrameElement).contentWindow;
    if (!window) {
        log.error('handleAccessIframe window is null');
        return false;
    }
    const { document } = window;
    if (!document) {
        log.error('handleAccessIframe document is null');
        return false;
    }

    const style = document.createElement('style');
    style.innerHTML = avalaraIframeStyles;
    document.head.appendChild(style);
    return true;
};

/**
 * Context:
 * Avalara script relies on 3 levels of indirection: the first script loads another script etc.
 * The `GenCert.init` does following, among other things:
 *    ```
 *    // loads  "/html/gencert/index.html";
 *    document.getElementById('gencert_iframe').contentWindow.document.write(xmlhttp.responseText);
 *    document.getElementById('gencert_iframe').contentWindow.document.close();
 *    ```
 * Virtually they wipe everything in the iframe and replace it with the content of the response.
 * There is no callback nor event to know when the iframe content is ready.
 * Thus we hook into the document.close method, to simulate this event.
 * @param formContainer
 * @param customCallback
 */
function hookIframeDocumentClose(formContainer: HTMLDivElement, customCallback: () => void) {
    const iframe = formContainer?.firstElementChild as HTMLIFrameElement;
    if (!iframe) {
        log.error('hookIframeDocumentClose iframe is null');
        return;
    }

    const contentDocument = iframe.contentDocument || iframe.contentWindow?.document;
    if (!contentDocument) {
        log.error('hookIframeDocumentClose iframe content document is null');
        return;
    }

    const originalClose = contentDocument.close;
    contentDocument.close = function close() {
        originalClose.apply(this);
        customCallback();
    };
}

/**
 * This component assumes that the script from "${env[ACCOUNTS_AVALARA_EXEMPTIONS_API_URL]}/js/gencert.js" has already been loaded and run
 */
export const AvalaraEcmPlugin = ({ token, state, onClose }: AvalaraEcmPluginProps) => {
    const [formContainer, setFormContainer] = useState<HTMLDivElement | null>(null);
    const [isAvalaraInitError, setIsAvalaraInitError] = useState(false);
    const [isAvalaraInitLoading, setIsAvalaraInitLoading] = useState(false);
    const refetchCertificates = useInvalidateCertificatesQuery();
    const customStylesEnabled = useFeatureFlagEnabled('embedded_tax_exemptions___custom_styles');

    if (state.length <= 2) {
        log.warn('AvalaraEcmPlugin: state is not a full name:', state);
    }

    const onCertSuccess = useCallback(() => {
        pushSuccessNotification('Tax exemption certificate has been added.');
        refetchCertificates();
        // account for the delay on Avalara's side
        setTimeout(refetchCertificates, 1000);
        onClose();
        // omitting dependencies, GenCert.init is out of React lifecycle anyway
    }, []);

    useEffect(() => {
        if (formContainer && token && state) {
            try {
                setIsAvalaraInitLoading(true);
                window.GenCert.init(formContainer, {
                    ship_zone: state,
                    gencertAPI: avalaraApiUrl,
                    token,
                    afterShow: () => {
                        // Empirically determined that it takes another 1s after `show` callback to actually show something
                        setTimeout(() => setIsAvalaraInitLoading(false), 1000);
                        if (customStylesEnabled) {
                            hookIframeDocumentClose(formContainer, () => applyCustomStyles(formContainer));
                        }
                    },
                    onCertSuccess,
                    // Avalara discerns those, but for us the user experience is the same
                    onUpload: onCertSuccess,
                    onValidateFailure: () => {
                        pushErrorNotification('There was an error with the validation.');
                    },
                    onCertFailure: () => {
                        pushErrorNotification('Certificate validation failed.');
                    },
                    onNotNeeded: () => {
                        pushNotification({
                            message: "The selected zone doesn't charge sales tax",
                            variant: 'warning',
                        });
                    },
                });
                window.GenCert.show();
            } catch (error) {
                setIsAvalaraInitError(true);
            }
        }
    }, [formContainer, token, state]);

    // The form container will be accessed outside React. We need to render the same object for the lifecycle of this component.
    // The margin is empirically determined, to align avalara page with our dialog spacing
    const renderFormContainer = useMemo(() => <div style={{ marginLeft: -12 }} ref={setFormContainer} />, []);

    return (
        <>
            {isAvalaraInitError && <ErrorLoadingAvalaraAlert />}
            {isAvalaraInitLoading && <Loader />}
            {renderFormContainer}
        </>
    );
};
