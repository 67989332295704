import { useTaxExemptionsRepository } from '~/adapters/services/accounts/taxExemptionsECM/rest';
import { useAccount, useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    BusinessAddressWName,
    CreateCustomerParams,
    UpdateCustomerParams,
} from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useCallback, useState } from 'react';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';

const taxExemptionsQueryKey = 'tax-exemptions';

export const useGetCertificates = () => {
    const { getCertificates } = useTaxExemptionsRepository();
    const { id } = useAccount();

    return useQuery({
        queryKey: [taxExemptionsQueryKey, 'certificates', id],
        queryFn: () => getCertificates(id),
    });
};

export const useInvalidateCertificatesQuery = () => {
    const queryClient = useQueryClient();
    const { id } = useAccount();
    return () => queryClient.invalidateQueries({ queryKey: [taxExemptionsQueryKey, 'certificates', id] });
};

export const useGetCustomer = () => {
    const { getCustomer } = useTaxExemptionsRepository();
    const { id } = useAccount();

    return useQuery({
        queryKey: [taxExemptionsQueryKey, 'customer', id],
        queryFn: () => getCustomer(id),
    });
};

export const useCreateCustomer = () => {
    const { createCustomer } = useTaxExemptionsRepository();
    const { id } = useAccount();

    return useMutation({
        mutationFn: (payload: CreateCustomerParams) => createCustomer(id, payload),
        // Intentionally not updating the cache here, the AddBusinessAddressButton needs to handle that in a more complex way.
    });
};

export const useUpdateCustomer = () => {
    const { updateCustomer } = useTaxExemptionsRepository();

    return useMutation({
        mutationFn: ({ customerId, payload }: { customerId: number; payload: UpdateCustomerParams }) =>
            updateCustomer(customerId, payload),
        // Intentionally not updating the cache here, the AddBusinessAddressButton needs to handle that in a more complex way.
    });
};

export const useCreateOrGetTaxExemptionPortalLink = ({ enabled }: EnabledQueryOption = {}) => {
    const { createOrGetTaxExemptionPortalLink } = useTaxExemptionsRepository();
    const { id } = useAccount();

    return useQuery({
        queryKey: [taxExemptionsQueryKey, 'portal-link', id],
        // The endpoint method is idempotent, thus we intentionally choose to use query instead of mutation even though the method is PUT
        queryFn: () => createOrGetTaxExemptionPortalLink(id),
        enabled,
        retry: false,
    });
};

export const useCreateOrUpdateBusinessAddress = () => {
    const {
        account: { contactEmail },
        user: { email },
    } = useUserAccount();
    const { data: customer } = useGetCustomer();
    const createCustomerMutation = useCreateCustomer();
    const updateCustomerMutation = useUpdateCustomer();
    const [hasError, setHasError] = useState(false);

    const onError = useCallback(() => {
        setHasError(true);
    }, [setHasError]);
    const onSuccess = useCallback(() => {
        setHasError(false);
    }, [setHasError]);

    const mutateAsync = useCallback(
        ({ organizationName: name, ...restBusinessAddress }: BusinessAddressWName) => {
            const businessAddress = {
                ...restBusinessAddress,
                line2: restBusinessAddress.line2 ?? undefined,
            };
            if (customer) {
                return updateCustomerMutation.mutateAsync(
                    {
                        customerId: customer.id,
                        payload: {
                            businessAddress,
                            name,
                        },
                    },
                    { onError, onSuccess },
                );
            }
            return createCustomerMutation.mutateAsync(
                {
                    businessAddress,
                    name,
                    emailAddress: contactEmail ?? email,
                },
                { onError, onSuccess },
            );
        },
        [customer, onError, onSuccess, createCustomerMutation, updateCustomerMutation],
    );

    return { mutateAsync, isPending: createCustomerMutation.isPending || updateCustomerMutation.isPending, hasError };
};

export const useCreateOrGetEcommerceToken = ({ enabled }: EnabledQueryOption = {}) => {
    const { createOrGetEcommerceToken } = useTaxExemptionsRepository();
    const { id } = useAccount();

    return useQuery({
        queryKey: [taxExemptionsQueryKey, 'ecommerce-token', id],
        // The endpoint method is idempotent, thus we intentionally choose to use query instead of mutation even though the method is PUT
        queryFn: () => createOrGetEcommerceToken(id),
        enabled,
        retry: false,
        staleTime: 1000 * 60 * 30, // 30 mins
    });
};
