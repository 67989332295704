import { Autocomplete, TextField } from '@mui/material';
import { usStatesAndTerritoriesByCode, usStatesByCode } from '~/adapters/geography/usStatesAndTerritories';
import { Dispatch, SetStateAction } from 'react';

/* eslint-disable react/jsx-props-no-spreading */

export type SelectStateProps = {
    value: string | null;
    setValue: Dispatch<SetStateAction<string | null>>;
    error: string | null;
};

const taxExemptionStates = Object.keys(usStatesByCode)
    .filter((code) => code !== 'AK')
    .sort();

function SelectState({ value, setValue, error }: SelectStateProps) {
    return (
        <Autocomplete
            autoSelect
            autoHighlight
            options={taxExemptionStates}
            getOptionLabel={(option) => usStatesAndTerritoriesByCode[option] ?? option}
            value={value}
            onChange={(_event, newValue) => setValue(newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="State or Territory"
                    placeholder="Select State or Territory..."
                    inputProps={{
                        autoComplete: 'address-level1',
                        ...params.inputProps,
                        'data-testid': 'state',
                    }}
                    autoFocus
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
}

export default SelectState;
