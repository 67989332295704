import { Container, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { CardListLoader } from '~/components/Layout/CardListLoader';
import { SectionContent } from '~/components/Layout/SectionContent';
import { PageHeaderLayout } from '~/components/PageHeader/PageHeader';
import { useEffect } from 'react';
import { log } from '~/adapters/browser/localLogger';
import { AppLayout, Content, MainContentLayout } from '~/components/Layout/FullLayoutComponents';
import { navigationWidth } from '~/components/Navigation/styles';

const StyledSkeleton = styled(Skeleton)({
    transform: 'none !important',
});

const NavigationWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexBasis: navigationWidth,
        flexShrink: 0,
    },
}));

type FullLayoutLoaderProps = {
    devLabel?: string;
};

export function FullLayoutLoader({ devLabel }: FullLayoutLoaderProps) {
    const theme = useTheme();
    const showDesktopMenu = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        log.info('💀 Render skeleton while loading ', devLabel);
    }, [devLabel]);

    return (
        <Container maxWidth="xl">
            <AppLayout>
                <Stack gap={3} sx={{ pl: { lg: 2 } }}>
                    <Stack direction="row">
                        <Skeleton variant="text" width="300px" height="48px" />
                    </Stack>
                </Stack>
                <MainContentLayout>
                    <NavigationWrapper>
                        {showDesktopMenu ? (
                            <Stack direction="column" spacing={2} sx={{ pl: { lg: 2 }, width: '96%' }}>
                                <StyledSkeleton variant="rounded" width="100%" height="30px" />
                                <StyledSkeleton variant="rounded" width="100%" height="30px" />
                                <StyledSkeleton variant="rounded" width="100%" height="30px" />
                                <StyledSkeleton variant="rounded" width="100%" height="30px" />
                                <StyledSkeleton variant="rounded" width="100%" height="30px" />
                            </Stack>
                        ) : (
                            <Stack direction="row">
                                <Skeleton variant="rounded" width="200px" />
                                <Skeleton variant="rounded" width="100px" sx={{ marginLeft: 'auto' }} />
                            </Stack>
                        )}
                    </NavigationWrapper>
                    <Content>
                        <PageHeaderLayout>
                            <StyledSkeleton variant="rounded" width="175px" height="30px" />
                        </PageHeaderLayout>
                        <SectionContent>
                            <CardListLoader />
                        </SectionContent>
                    </Content>
                </MainContentLayout>
            </AppLayout>
        </Container>
    );
}
