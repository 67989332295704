import { PropsWithChildren } from 'react';
import { Stack } from '@customink/pigment-react';
import { StorePurchaseSummary } from '~/adapters/services/accounts/orders/types';
import { StorePurchaseCard } from './StorePurchaseCard/StorePurchaseCard';
import { useStoresLinks } from '../../../adapters/services/accounts/stores/links';

interface Props {
    storePurchasesItems: Array<StorePurchaseSummary>;
}

export function StorePurchasesList(props: PropsWithChildren<Props>) {
    const { storePurchasesItems } = props;
    const { createHealthAndWellnessLink } = useStoresLinks();

    return (
        <>
            <Stack spacing={2}>
                {storePurchasesItems.map((card) => (
                    <StorePurchaseCard
                        key={card.referenceId}
                        card={card}
                        purchaseLink={createHealthAndWellnessLink(card.secureId)}
                    />
                ))}
            </Stack>
        </>
    );
}
