import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import {
    Alert,
    ArrowUndoIcon,
    Button,
    Dialog,
    DialogCommonActions,
    DialogContent,
    Stack,
    Typography,
} from '@customink/pigment-react';
import { Loader } from '~/components/Loader/loader';
import './genCert.d';
import { useCreateOrGetEcommerceToken } from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { AvalaraEcmPlugin } from '~/pages/Settings/tabs/TaxExemptionECM/EmbeddedPortal/AvalaraEcmPlugin';
import { ErrorLoadingAvalaraAlert } from '~/pages/Settings/tabs/TaxExemptionECM/EmbeddedPortal/ErrorLoadingAvalaraAlert';
import SelectState from '~/pages/Settings/tabs/TaxExemptionECM/EmbeddedPortal/SelectState';
import { ChatWithUs } from '~/pages/Settings/tabs/TaxExemptionECM/ChatWithUs';
import useScript from '~/adapters/browser/useScript';
import { getEnv } from '~/adapters/config/env';
import { usStatesAndTerritoriesByCode } from '~/adapters/geography/usStatesAndTerritories';
import { signaler } from '~/adapters/signaler/signaler';

type ETEDialogProps = {
    open: boolean;
    onClose: () => void;
};

const avalaraApiUrl = getEnv('ACCOUNTS_AVALARA_EXEMPTIONS_API_URL');

export const ETEDialog: FC<ETEDialogProps> = ({ open, onClose }) => {
    const [step, setStep] = useState<0 | 1>(0);

    const [state, setState] = useState<string | null>(null);
    const [stateErr, setStateErr] = useState<string | null>(null);

    // Token query lifted up here for quicker loading, regardless of `open` prop
    const tokenQuery = useCreateOrGetEcommerceToken();
    const [tokenExpired, setTokenExpired] = useState(false);
    const avalaraScript = useScript(`${avalaraApiUrl}/js/gencert.js`);

    useEffect(() => {
        let timeoutHandle: NodeJS.Timeout;
        if (tokenQuery.data?.expirationDate) {
            const expiration = new Date(`${tokenQuery.data.expirationDate}Z`); // Z to mark as UTC
            timeoutHandle = setTimeout(() => {
                setTokenExpired(true);
            }, expiration.getTime() - Date.now());
        }
        return () => clearTimeout(timeoutHandle);
    }, [tokenQuery.data?.expirationDate]);

    const handleContinue = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            if (state) {
                setStep(1);
            } else {
                setStateErr('Please select a state or territory for your tax exemption.');
            }
        },
        [state],
    );

    const handleStartOver = useCallback((event: MouseEvent) => {
        event.preventDefault();
        setStep(0);
        setState(null);
        setStateErr(null);
    }, []);

    // TODO @teichmaa 9.1.2025: Remove this after Jirka finishes testing
    // eslint-disable-next-line no-console
    console.log('Nautilus tokenQuery', tokenQuery.data);

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                title="Submit Your Tax Exemption Certificate"
                width={
                    step === 0
                        ? 'md'
                        : (signaler.featureFlag('embedded_tax_exemptions___width') as unknown as
                              | 'md'
                              | 'lg'
                              | 'sm'
                              | 'full')
                }
                disableRestoreFocus
            >
                <DialogContent nonScrollable>
                    <Typography>
                        Follow the steps below to verify your exemption and complete your submission. Approvals are
                        typically instant but may take up to 3 business days in some states.
                    </Typography>
                </DialogContent>
                <DialogContent dividers={step === 1}>
                    <Stack spacing={1}>
                        {tokenExpired && (
                            <Alert severity="warning">
                                Tax Exemption session has expired after 60 minutes. Please refresh the page to continue.
                            </Alert>
                        )}
                        {step === 0 && (
                            <form id="select-state" style={{ maxWidth: 340, marginTop: 16 }}>
                                <SelectState value={state} setValue={setState} error={stateErr} />
                            </form>
                        )}
                        {step === 1 && (tokenQuery.isError || avalaraScript.isError) && <ErrorLoadingAvalaraAlert />}
                        {step === 1 && (tokenQuery.isPending || avalaraScript.isLoading) && <Loader />}
                        {step === 1 && tokenQuery.isSuccess && avalaraScript.isLoaded && state && (
                            <>
                                <Button
                                    startIcon={<ArrowUndoIcon />}
                                    sx={{ alignSelf: 'flex-start' }}
                                    variant="link"
                                    onClick={handleStartOver}
                                >
                                    Start Over
                                </Button>
                                <AvalaraEcmPlugin
                                    token={tokenQuery.data.token}
                                    state={usStatesAndTerritoriesByCode[state]}
                                    onClose={onClose}
                                />
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogCommonActions>
                    <Button
                        type="submit"
                        form="select-state"
                        onClick={handleContinue}
                        sx={{ display: step === 1 ? 'none' : undefined }}
                    >
                        Continue
                    </Button>
                    <div />
                    <ChatWithUs />
                </DialogCommonActions>
            </Dialog>
        </>
    );
};
