import { useState, useEffect } from 'react';

/**
 * Guaranteed to be idempotent. Multiple calls will result in only one script tag being added to the DOM.
 * @param src
 */
const useScript = (src: string) => {
    const [isLoaded, setLoaded] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
        let scriptEl: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
        if (!scriptEl) {
            scriptEl = document.createElement('script');
            scriptEl.src = src;
            scriptEl.async = true;
            document.head.appendChild(scriptEl);
        } else {
            setLoaded(scriptEl.getAttribute('data-loaded') === 'true');
            setError(scriptEl.getAttribute('data-error') === 'true');
        }

        const onScriptLoad = () => {
            setLoaded(true);
            scriptEl?.setAttribute('data-loaded', 'true');
        };

        const onScriptError = () => {
            setError(true);
            scriptEl?.setAttribute('data-error', 'true');
        };

        scriptEl.addEventListener('load', onScriptLoad);
        scriptEl.addEventListener('error', onScriptError);

        return () => {
            scriptEl?.removeEventListener('load', onScriptLoad);
            scriptEl?.removeEventListener('error', onScriptError);
        };
    }, [src]);

    return { isLoaded, isLoading: !isLoaded, isError };
};

export default useScript;
