import { Select, Stack } from '@customink/pigment-react';
import { SelectChangeEvent } from '@mui/material';
import { Dispatch, PropsWithChildren, SetStateAction, useCallback } from 'react';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import { TypedMenuItem } from '~/adapters/designSystem/TypedMenuItem';
import { OrderSortBy, OrderSummary } from '~/adapters/services/accounts/orders/types';
import { trackEvent } from '~/adapters/tracking/tracking';
import LoadMoreButton from '~/components/LoadMoreButton';
import { Loader } from '~/components/Loader/loader';
import { PageControlsMount } from '~/components/PageHeader/PageControls';
import { PageSort } from '~/components/PageHeader/PageSort';
import { gaCategory } from '~/pages/OrderHistory/tracking';
import { EmptyOrdersList } from './EmptyOrdersList';
import { OrderCard } from './OrderCard/OrderCard';

const OrdersSortByMenuItem = TypedMenuItem<OrderSortBy>;

interface Props {
    designLabLink: string;
    orders: Array<OrderSummary>;
    onLoadMore: () => void;
    loading: boolean;
    filtersChangedLoading: boolean;
    hasMorePages: boolean;
    sort: OrderSortBy;
    onSortChange: Dispatch<SetStateAction<OrderSortBy>>;
}

export function OrdersList(props: PropsWithChildren<Props>) {
    const { orders, onLoadMore, loading, hasMorePages, designLabLink, sort, onSortChange, filtersChangedLoading } =
        props;
    const ref = useScrollLoad({
        load: onLoadMore,
        enabled: !loading,
    });
    const handleSelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
        onSortChange(e.target.value as OrderSortBy);
        trackEvent({
            category: gaCategory,
            action: 'Orders Sort by',
            label: e.target.value as OrderSortBy,
        });
    }, []);

    return (
        <>
            <div ref={ref}>
                <PageControlsMount>
                    <PageSort>
                        <Select fullWidth value={sort} onChange={handleSelectChange}>
                            <OrdersSortByMenuItem value="-placedAt">Newest</OrdersSortByMenuItem>
                            <OrdersSortByMenuItem value="+placedAt">Oldest</OrdersSortByMenuItem>
                            <OrdersSortByMenuItem value="-totalPrice">Order Total: High to low</OrdersSortByMenuItem>
                            <OrdersSortByMenuItem value="+totalPrice">Order Total: Low to high</OrdersSortByMenuItem>
                            <OrdersSortByMenuItem value="-designName">Design name: Z-A</OrdersSortByMenuItem>
                            <OrdersSortByMenuItem value="+designName">Design name: A-Z</OrdersSortByMenuItem>
                        </Select>
                    </PageSort>
                </PageControlsMount>
                {filtersChangedLoading ? (
                    <Loader title="Loading..." />
                ) : (
                    <Stack spacing={2}>
                        {orders.map((order) => (
                            <OrderCard key={order.id} order={order} singleDetail={false} />
                        ))}
                    </Stack>
                )}
                {orders.length === 0 && <EmptyOrdersList designLabLink={designLabLink} />}
            </div>
            {hasMorePages && <LoadMoreButton onClick={onLoadMore} loading={loading} />}
        </>
    );
}
